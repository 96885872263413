body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NeinorNormal';
  src: local('NeinorNormal'),
    url('./fonts/ITC Avant Garde Gothic Std/ITCAvantGardeStd-Bk.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'NeinorFina';
  src: local('NeinorFina'),
    url('./fonts/ITC Avant Garde Gothic Std/ITCAvantGardeStd-XLt.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'ITCAvantGardeStd-Demi';
  src: local('ITCAvantGardeStd-Demi'),
    url('./fonts/ITC Avant Garde Gothic Std/ITCAvantGardeStd-DemiCn.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'ITCAvantGardeStd-Md';
  src: local('ITCAvantGardeStd-Md'),
    url('./fonts/ITC Avant Garde Gothic Std/ITCAvantGardeStd-Md.otf') format('opentype');
  font-weight: normal;
}
